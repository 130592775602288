import _setAttributes from "./lib/set-attributes";
import _defaultAttributes from "./lib/default-attributes";
import _assign from "./lib/assign";
var exports = {};
var set_attributes_1 = _setAttributes;
var default_attributes_1 = _defaultAttributes;
var assign_1 = _assign;
exports = function createFrame(options) {
  if (options === void 0) {
    options = {};
  }
  var iframe = document.createElement("iframe");
  var config = (0, assign_1.assign)({}, default_attributes_1.defaultAttributes, options);
  if (config.style && typeof config.style !== "string") {
    (0, assign_1.assign)(iframe.style, config.style);
    delete config.style;
  }
  (0, set_attributes_1.setAttributes)(iframe, config);
  if (!iframe.getAttribute("id")) {
    iframe.id = iframe.name;
  }
  return iframe;
};
export default exports;
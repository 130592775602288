var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.assign = void 0;
function assign(
// eslint-disable-next-line @typescript-eslint/no-explicit-any
target) {
  var objs = [];
  for (var _i = 1; _i < arguments.length; _i++) {
    objs[_i - 1] = arguments[_i];
  }
  objs.forEach(function (obj) {
    if (typeof obj !== "object") {
      return;
    }
    Object.keys(obj).forEach(function (key) {
      target[key] = obj[key];
    });
  });
  return target;
}
exports.assign = assign;
export default exports;
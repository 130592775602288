var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.defaultAttributes = void 0;
exports.defaultAttributes = {
  src: "about:blank",
  frameBorder: 0,
  allowtransparency: true,
  scrolling: "no"
};
export default exports;